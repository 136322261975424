/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import SelectLink from '../SelectLink/SelectLink';

StyledLink.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  style: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  linkActive: PropTypes.bool,
  aria: PropTypes.string,
  handleClick: PropTypes.func,
};

export default function StyledLink({
  children,
  disabled,
  to = '/',
  className = '',
  linkActive,
  aria = '',
  handleClick,
}) {
  let aggregateClassName = `d-flex align-items-center ${className}`;

  aggregateClassName = disabled ? `disabled ${aggregateClassName}` : aggregateClassName;

  const removeFocus = () => {
    document.activeElement.blur();
  };

  const combinedHandler = () => {
    removeFocus();
    handleClick();
  };

  return (
    <span className={aggregateClassName}>
      <SelectLink handleClick={combinedHandler} {...{ disabled, to, aria }}>
        <span className={linkActive ? 'active' : ''}>{children}</span>
      </SelectLink>
    </span>
  );
}
