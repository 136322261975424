import React from 'react';

const GooglePayMark = () => (
  <svg
    width="70"
    height="35"
    viewBox="0 0 96 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="google-pay-mark"
  >
    <title id="google-pay-mark">Google Pay</title>
    <rect x="1" y="1" width="94" height="58" rx="16" fill="white" />
    <path
      d="M45.8019 31.6486V39.2402H43.3763V20.4934H49.8065C51.4362 20.4934 52.8258 21.033 53.9628 22.1121C55.125 23.1912 55.7061 24.5088 55.7061 26.0647C55.7061 27.6583 55.125 28.9759 53.9628 30.0425C52.8384 31.109 51.4488 31.6361 49.8065 31.6361H45.8019V31.6486ZM45.8019 22.8022V29.3398H49.8571C50.8172 29.3398 51.6257 29.0135 52.2573 28.3736C52.9016 27.7336 53.2301 26.9556 53.2301 26.0773C53.2301 25.2115 52.9016 24.446 52.2573 23.8061C51.6257 23.141 50.8298 22.8148 49.8571 22.8148H45.8019V22.8022Z"
      fill="#3C4043"
    />
    <path
      d="M62.0479 25.9895C63.8418 25.9895 65.2567 26.4663 66.2926 27.42C67.3285 28.3736 67.8464 29.6786 67.8464 31.335V39.2402H65.5346V37.4584H65.4335C64.4355 38.9265 63.0964 39.6543 61.4289 39.6543C60.0013 39.6543 58.8138 39.2402 57.8537 38.3995C56.8936 37.5588 56.4136 36.5173 56.4136 35.2625C56.4136 33.9324 56.9189 32.8784 57.9295 32.1004C58.9402 31.3099 60.2919 30.9209 61.9721 30.9209C63.4123 30.9209 64.5998 31.1844 65.522 31.7114V31.1593C65.522 30.3186 65.1935 29.6159 64.524 29.0261C63.8544 28.4364 63.0712 28.1478 62.1742 28.1478C60.8225 28.1478 59.7487 28.7124 58.9654 29.8543L56.8305 28.5242C58.0053 26.8302 59.7487 25.9895 62.0479 25.9895ZM58.9149 35.3001C58.9149 35.9275 59.1802 36.4546 59.7234 36.8686C60.254 37.2827 60.8857 37.496 61.6057 37.496C62.629 37.496 63.5386 37.1196 64.3345 36.3667C65.1303 35.6138 65.5346 34.7355 65.5346 33.7191C64.7766 33.1293 63.7281 32.8282 62.3763 32.8282C61.391 32.8282 60.5698 33.0666 59.9129 33.5309C59.2434 34.0202 58.9149 34.61 58.9149 35.3001Z"
      fill="#3C4043"
    />
    <path
      d="M81.0352 26.4036L72.9501 44.8743H70.4488L73.4554 38.412L68.1243 26.4036H70.7646L74.605 35.6138H74.6556L78.3949 26.4036H81.0352Z"
      fill="#3C4043"
    />
    <path
      d="M36.1541 30.1178C36.1541 29.3323 36.0833 28.5807 35.9519 27.8579H25.7849V31.9988L31.6403 32C31.4028 33.3778 30.6385 34.5523 29.4674 35.3353V38.0218H32.9529C34.988 36.1509 36.1541 33.3853 36.1541 30.1178Z"
      fill="#4285F4"
    />
    <path
      d="M29.4687 35.3353C28.4985 35.9852 27.2491 36.3655 25.7875 36.3655C22.964 36.3655 20.5688 34.4757 19.711 31.9285H16.1157V34.6991C17.8969 38.21 21.5579 40.6192 25.7875 40.6192C28.7107 40.6192 31.1666 39.6643 32.9542 38.0205L29.4687 35.3353Z"
      fill="#34A853"
    />
    <path
      d="M19.3724 29.8731C19.3724 29.1579 19.4924 28.4665 19.711 27.8165V25.0459H16.1156C15.3791 26.4977 14.9648 28.1365 14.9648 29.8731C14.9648 31.6098 15.3804 33.2485 16.1156 34.7004L19.711 31.9298C19.4924 31.2798 19.3724 30.5884 19.3724 29.8731Z"
      fill="#FABB05"
    />
    <path
      d="M25.7875 23.3795C27.383 23.3795 28.8118 23.9254 29.9399 24.9919L33.0287 21.9265C31.1527 20.1911 28.707 19.1257 25.7875 19.1257C21.5592 19.1257 17.8969 21.535 16.1157 25.0459L19.711 27.8165C20.5688 25.2692 22.964 23.3795 25.7875 23.3795Z"
      fill="#E94235"
    />
    <rect x="1" y="1" width="94" height="58" rx="28" stroke="black" strokeWidth="2" />
  </svg>
);

export default GooglePayMark;
