import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let appInsights = null;

export const appInsightsService = () => {
  const maxBatchInterval = process.env.MAX_BATCH_INTERVAL ?? 15000;

  if (!process.env.INSTRUMENTATION_KEY) {
    console.error('Application insights: no instrumentation key provided');
    return { appInsights };
  }
  if (appInsights === null) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.INSTRUMENTATION_KEY,
        disableAjaxTracking: true,
        autoTrackPageVisitTime: true,
        maxBatchInterval,
      },
    });
    appInsights.loadAppInsights();
  }
  return { appInsights };
};
