/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { footerCopy } from '../../copy';
import { trackFooterEvent } from '../../utils/appInsightsCustomEvents';
import theme from '../../utils/theme';
import opusBrandMark from '../../assets/hero-banner/opusBrandMark.png';
import GooglePayMark from '../Icons//GooglePayMark';
import ApplePayMark from '../Icons//ApplePayMark';
import DOMPurify from 'isomorphic-dompurify';

export default function Footer() {
  return (
    <footer css={footer} data-cy="footer">
      <div className="container">
        <div css={content}>
          <div css={body}>
            <p css={bodyOne}>{footerCopy.bodyOne}</p>
          </div>
          <div className="row justify-content-between">
            <div className="col-12 col-lg-8">
              <div className="row">
                <div className="col-12">
                  {footerCopy.links.map((link, index) => (
                    <span css={linksWrapperStyle} key={index}>
                      <a
                        href={link.url}
                        aria-label={`${link.text} ${link.ariaText}`}
                        css={linkStyle}
                        target={link.ariaText === 'opens in a new tab' ? '_blank' : '_self'}
                        rel="noreferrer"
                        onClick={() => trackFooterEvent(link.text)}
                      >
                        {link.text}
                      </a>
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4" css={storesAvailable}>
              Available to use with
              <GooglePayMark />
              <ApplePayMark />
            </div>
          </div>
          <div css={body}>
            <p>{footerCopy.bodyTwo}</p>
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(footerCopy.bodyThree) }}></p>
            <p> {footerCopy.bodyFour}</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

const linksWrapperStyle = css`
  margin-right: 2rem;
  display: inline-block;
`;

const footer = css`
  font-family: 'FilsonSoftBook';
  background:
    url(${opusBrandMark}) no-repeat 75% 40%,
    linear-gradient(to right, ${theme.grey200} 70%, ${theme.white} 100%);
  padding-bottom: 3.75rem;
  padding-top: 2rem;
`;

const body = css`
  font-weight: 300;
  font-size: 0.875rem;
  padding: 1.375rem 0;
  a {
    font-family: 'FilsonSoftMedium';
    color: ${theme.teal};
    transition: 0.2s;
    white-space: nowrap;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;
const bodyOne = css`
  margin-bottom: 0;
  font-weight: 700;
  font-size: 1rem;
`;

const linkStyle = css`
  font-family: 'FilsonSoftMedium';
  color: ${theme.teal};
  transition: 0.2s;
  line-height: 3.5;
  white-space: nowrap;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

const content = css`
  margin-right: auto;
  margin-left: auto;
  font-family: 'FilsonSoftBook';
  font-size: 0.875rem;
  color: ${theme.shadowGrey};
`;
const storesAvailable = css`
  line-height: 3.5;
  svg {
    max-width: 50px;
    margin-left: 0.5rem;
  }
`;
