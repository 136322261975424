import { css } from '@emotion/react';
import theme from '../../utils/theme';

export const headerWrapper = css`
  position: -webkit-fixed;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  isolation: isolate;
  width: 100%;
  height: 3.75rem;
  color: ${theme.teal};
`;

export const header = css`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: transparent;
  transition:
    box-shadow 0.6s,
    background-color 0.6s;

  &.scrolled {
    background-color: white;
    box-shadow: 0 5px 8px -6px ${theme.shadowGrey};
  }

  &.mobile-menu {
    background-color: white;
    border-bottom: 1px solid ${theme.lightGrey};
  }
`;

export const skipToMainContent = css`
  position: absolute;
  top: -200px;
  left: 0;
  right: 0;
  z-index: 1;

  width: fit-content;
  padding: 0.6rem;
  margin: auto;

  color: ${theme.teal};
  background-color: ${theme.white};
  text-decoration: none;
  border-radius: 0.375rem;

  transition: all 0.6s ease-in-out;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    top: 8px;
    text-decoration: none;
    border: 1px solid ${theme.teal} !important;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

export const skipToMainContentScrolled = css`
  background-color: ${theme.white};
  color: ${theme.teal};
`;

export const darkTopBarLinks = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const lightTopBarLinks = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const link = css`
  line-height: 1.875rem;
  transition: border-bottom 0.6s;
  a {
    border-radius: 0.375rem;
    height: 1.875rem;
    padding: 0 0.9rem;
    color: ${theme.teal};
    cursor: inherit;
    text-decoration: none;
    font-family: 'FilsonSoftBook';
    font-size: 1.125rem;
    span {
      box-shadow: none;
      transition: box-shadow 0.6s;
      padding: 0.4rem 0;
    }
    :hover {
      cursor: pointer;
      color: ${theme.teal};
      text-decoration: none;
    }
    :active {
      color: ${theme.teal};
      text-decoration: none;
    }
  }

  &.disabled {
    color: ${theme.grey600};
    cursor: not-allowed;
  }

  :hover:not(.disabled) {
    cursor: pointer;
    span {
      box-shadow: 0 1px 0 0 ${theme.lightGrey};
    }
  }

  span.active {
    box-shadow: 0 1px 0 0 ${theme.lightGrey};
  }
`;

export const linkDark = css`
  a {
    color: ${theme.teal};

    :focus {
      outline-offset: 2px;
      outline: 1px solid ${theme.teal};
    }

    :hover:not(.disabled) {
      span {
        box-shadow: 0 1px 0 0 ${theme.teal};
      }
    }
  }
`;

export const linkLight = css`
  a {
    color: ${theme.teal};
    :hover {
      text-decoration: none;
    }

    :focus {
      outline-offset: 2px;
      outline: 1px solid ${theme.teal};
    }
  }
`;

export const logoDark = css`
  transition: opacity 0.6s;

  &:focus {
    outline: 1px solid ${theme.teal};
  }
`;

export const logoLight = css`
  transition: opacity 0.6s;

  &:focus {
    outline: 1px solid ${theme.teal};
  }
`;

export const mobileMenuButtonDark = css`
  all: unset;
  margin-left: 1rem;

  &:focus {
    outline-offset: 2px !important;
    outline: 1px solid ${theme.teal} !important;
  }
`;

export const mobileMenuButtonLight = css`
  all: unset;
  margin-left: 1rem;
  &:focus {
    outline-offset: 2px;
    outline: 1px solid ${theme.teal};
  }
`;

export const mobileMenuWrapper = css`
  position: fixed;
  top: 0;
  overflow: hidden;
  isolation: isolate;
  z-index: 20;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  color: ${theme.teal};
  background-color: ${theme.white};
`;

export const mobileMenuHeader = css`
  width: 100%;
  max-width: 100vw;
  height: 3.75rem;

  border-bottom: 1px solid ${theme.lightGrey};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: transparent;
`;

export const mobileMenuItems = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  a {
    font-family: 'FilsonSoftBook';
    font-size: 1.125rem;
    color: inherit;
    padding: 0.1rem 1rem;
    border-radius: 0.375rem;
  }
`;
export const mobileMenuButtons = css`
  padding: 1rem 0.1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const loginButton = css`
  a {
    display: inline-block;
    margin-left: 5px;
    background-color: transparent;
    border: 1px solid ${theme.teal};
    border-radius: 100px;
    padding: 5px 40px;
    text-decoration: none;
    transition:
      background 0.2s ease-in-out,
      transform 150ms ease;
    color: ${theme.teal};
    font-size: 0.875rem;
    :hover {
      color: ${theme.white};
      text-decoration: none;
      background: ${theme.teal};
    }
  }
`;
