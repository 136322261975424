import styled from '@emotion/styled';

import FilsonSoftBoldItalic from '../../static/fonts/filson-soft/FilsonSoft-BoldItalic.ttf';
import FilsonSoftBold from '../../static/fonts/filson-soft/FilsonSoft-Bold.ttf';
import FilsonSoftBook from '../../static/fonts/filson-soft/FilsonSoftBook.ttf';
import FilsonSoftBookItalic from '../../static/fonts/filson-soft/FilsonSoftBook-Italic.ttf';
import FilsonSoftLight from '../../static/fonts/filson-soft/FilsonSoft-Light.ttf';
import FilsonSoftLightItalic from '../../static/fonts/filson-soft/FilsonSoftLight-Italic.ttf';
import FilsonSoftMedium from '../../static/fonts/filson-soft/FilsonSoftMedium.ttf';
import FilsonSoftMediumItalic from '../../static/fonts/filson-soft/FilsonSoftMedium-Italic.ttf';

export const FontWrapper = styled('div')`
  @font-face {
    font-family: 'FilsonSoftBoldItalic';
    src: url(${FilsonSoftBoldItalic}) format('truetype');
    font-style: italic;
    font-weight: bold;
  }

  @font-face {
    font-family: 'FilsonSoftBold';
    src: url(${FilsonSoftBold}) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'FilsonSoftBook';
    src: url(${FilsonSoftBook}) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'FilsonSoftBookItalic';
    src: url(${FilsonSoftBookItalic}) format('truetype');
    font-style: italic;
  }

  @font-face {
    font-family: 'FilsonSoftLight';
    src: url(${FilsonSoftLight}) format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'FilsonSoftLightItalic';
    src: url(${FilsonSoftLightItalic}) format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'FilsonSoftMedium';
    src: url(${FilsonSoftMedium}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'FilsonSoftMediumItalic';
    src: url(${FilsonSoftMediumItalic}) format('truetype');
    font-style: italic;
    font-weight: 400;
  }
`;
