import React from 'react';

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 30" width="100" height="50">
    <title>opus</title>
    <g id="Logo_opus" data-name="Logo/opus">
      <path
        id="Fill-1"
        fill="#009ca5"
        d="M18.72,10.31a8.84,8.84,0,0,0-.57-3.18,7.72,7.72,0,0,0-1.62-2.59A7.36,7.36,0,0,0,14,2.82a8.26,8.26,0,0,0-3.23-.61,8.26,8.26,0,0,0-3.23.61A7.35,7.35,0,0,0,5.05,4.54,7.7,7.7,0,0,0,3.44,7.13a8.83,8.83,0,0,0-.57,3.18,8.45,8.45,0,0,0,.59,3.14A7.91,7.91,0,0,0,5.07,16a7.49,7.49,0,0,0,2.49,1.72,8,8,0,0,0,3.23.63A8,8,0,0,0,14,17.74,7.72,7.72,0,0,0,16.53,16a7.5,7.5,0,0,0,1.62-2.57A8.73,8.73,0,0,0,18.72,10.31Zm2.87,0a10,10,0,0,1-.81,4,10.16,10.16,0,0,1-2.24,3.28,10.52,10.52,0,0,1-3.42,2.21,11.33,11.33,0,0,1-4.33.81,11.33,11.33,0,0,1-4.33-.81A10.51,10.51,0,0,1,3.05,17.6,10.14,10.14,0,0,1,.81,14.32a10,10,0,0,1-.81-4A10.12,10.12,0,0,1,3.05,3,10.27,10.27,0,0,1,6.47.79,11.57,11.57,0,0,1,10.79,0a11.58,11.58,0,0,1,4.33.79A10.29,10.29,0,0,1,18.53,3a10.12,10.12,0,0,1,3.05,7.33Z"
      />
      <path
        id="Fill-3"
        fill="#009ca5"
        d="M43.65,10.31a9,9,0,0,0-.55-3.14A7.4,7.4,0,0,0,41.53,4.6a7.56,7.56,0,0,0-2.47-1.72,8,8,0,0,0-3.25-.63,8.11,8.11,0,0,0-3.21.61,7.23,7.23,0,0,0-2.45,1.68,7.59,7.59,0,0,0-1.58,2.55A8.94,8.94,0,0,0,28,10.31a8.72,8.72,0,0,0,.57,3.14A7.65,7.65,0,0,0,30.17,16a7.8,7.8,0,0,0,2.47,1.74,7.58,7.58,0,0,0,3.17.65,8.21,8.21,0,0,0,3.25-.61,7.18,7.18,0,0,0,2.47-1.72,7.64,7.64,0,0,0,1.58-2.57A9.08,9.08,0,0,0,43.65,10.31Zm-18.35.95q0-.95.06-1.68t.14-1.28a10.94,10.94,0,0,1,1.25-3.48A9.16,9.16,0,0,1,29,2.21,9.76,9.76,0,0,1,32.05.57,12,12,0,0,1,35.81,0a11.33,11.33,0,0,1,4.33.81A10.31,10.31,0,0,1,43.53,3a10.05,10.05,0,0,1,2.2,3.28,10.25,10.25,0,0,1,.79,4,10.35,10.35,0,0,1-.79,4,9.91,9.91,0,0,1-5.6,5.47,11.58,11.58,0,0,1-4.33.79,10.12,10.12,0,0,1-4.65-1,9,9,0,0,1-2-1.37.71.71,0,0,0-1.19.5v8.57c0,.38-.81.68-1.19.68h-.39c-.39,0-1.08-.31-1.08-.68v-16Z"
      />
      <path
        id="Fill-5"
        fill="#009ca5"
        d="M66.86,1.9a1.38,1.38,0,0,1,.34-1A1.44,1.44,0,0,1,68.31.55a2.57,2.57,0,0,1,.71.1.69.69,0,0,1,.51.66V12.25a8.7,8.7,0,0,1-.65,3.4A7.43,7.43,0,0,1,67,18.29,8.47,8.47,0,0,1,64,20a11.93,11.93,0,0,1-3.94.61A11.75,11.75,0,0,1,56.18,20a8.5,8.5,0,0,1-3-1.72,7.42,7.42,0,0,1-1.86-2.65,8.69,8.69,0,0,1-.65-3.4V1.9a1.38,1.38,0,0,1,.34-1A1.44,1.44,0,0,1,52.18.55a2.57,2.57,0,0,1,.71.1.69.69,0,0,1,.51.66V11.46a7.29,7.29,0,0,0,1.66,5.1,6.51,6.51,0,0,0,5.05,1.82,6.58,6.58,0,0,0,5.07-1.82,7.24,7.24,0,0,0,1.68-5.1V1.9"
      />
      <path
        id="Fill-6"
        fill="#009ca5"
        d="M89.53,1.42q1.13.63,1.13,1.46a1.35,1.35,0,0,1-.3.91l-.12.13a.72.72,0,0,1-.93.1L89,3.83a8.64,8.64,0,0,0-1.5-.73,14.09,14.09,0,0,0-2.28-.65,15.28,15.28,0,0,0-3.07-.28,10.81,10.81,0,0,0-4.49.77A2.63,2.63,0,0,0,76,5.49a2.46,2.46,0,0,0,1.11,2.17,7.08,7.08,0,0,0,3.13,1l4.61.51a15.1,15.1,0,0,1,3.33.73,7,7,0,0,1,2.18,1.2,4.21,4.21,0,0,1,1.19,1.6,5.38,5.38,0,0,1-.2,4.35,5.25,5.25,0,0,1-1.7,1.88,9,9,0,0,1-2.81,1.24,15,15,0,0,1-3.9.45,20.37,20.37,0,0,1-3.72-.32,30.07,30.07,0,0,1-3-.69,13.34,13.34,0,0,1-2-.75l-.3-.15a.6.6,0,0,1-.23-.39,1.36,1.36,0,0,1,.93-1.67.6.6,0,0,1,.4,0l.35.16a15.35,15.35,0,0,0,1.8.69,20.8,20.8,0,0,0,2.55.63,17.12,17.12,0,0,0,3.19.28,15.65,15.65,0,0,0,2.43-.18,7,7,0,0,0,2-.59,3.68,3.68,0,0,0,1.33-1A2.52,2.52,0,0,0,89.17,15a2.86,2.86,0,0,0-1-2.31,7.6,7.6,0,0,0-3.84-1.17l-4.24-.47a9.5,9.5,0,0,1-5-1.74,4.52,4.52,0,0,1-1.72-3.71,5.09,5.09,0,0,1,.44-2.07,4.64,4.64,0,0,1,1.48-1.8A8,8,0,0,1,78,.47,15.87,15.87,0,0,1,82.22,0a18.84,18.84,0,0,1,4.55.47,13,13,0,0,1,2.77.95"
      />
    </g>
  </svg>
);

export default Logo;
