/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

SelectLink.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string,
  aria: PropTypes.string,
  handleClick: PropTypes.func,
  passedStyle: PropTypes.any,
  dataCy: PropTypes.string,
};

// please install the 'Comment anchors' extension to use the below link
// LINK README.md#select-link
export default function SelectLink({
  children,
  to = '',
  aria = '',
  handleClick,
  passedStyle,
  dataCy = '',
}) {
  const internalLink = to.startsWith('/');
  let ariaLabel = aria;
  const ariaEmptyAndChildIsString = aria === '' && typeof children === 'string';

  if (ariaEmptyAndChildIsString) {
    ariaLabel = children;
  }

  return internalLink ? (
    <Link css={passedStyle} onClick={handleClick} to={to} aria-label={ariaLabel} data-cy={dataCy}>
      {children}
    </Link>
  ) : (
    <a
      onClick={handleClick}
      id="external-link"
      href={to}
      target="_blank"
      rel="noreferrer"
      aria-label={`${ariaLabel} opens in a new tab`}
      css={passedStyle}
      data-cy={dataCy}
    >
      {children}
    </a>
  );
}
