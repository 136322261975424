import { appInsightsService } from './appInsightsService';

import { isMobile, isTablet } from 'react-device-detect';

const device = () => {
  if (isMobile) {
    return 'mobile';
  } else if (isTablet) {
    return 'tablet';
  }
  return 'desktop';
};

const commonProperties = {
  ApplicationName: 'OwnBrands.HomePage',
  Brand: 'OPUS',
};

const appins = appInsightsService().appInsights;

export const trackPageViews = (pageName) => {
  appins.trackPageView({
    name: pageName,
    properties: {
      device: device(),
      ...commonProperties,
    },
  });
};

export const trackEvents = (eventName) => {
  const formattedName = `opuscard.co.uk: ${eventName}`;
  appins.trackEvent({
    name: formattedName,
    properties: {
      device: device(),
      ...commonProperties,
    },
  });
};
