export default {
  white: '#FFFFFF',
  lightGrey: '#F0F0F0',
  shadowGrey: '#283237',
  darkTeal: '#006272',
  teal: '#007680',
  lightTeal: '#e1f3f4',
  buttonTeal: '#45D8C0',
  black: '#1E1E1E',
  grey200: '#f6f6f6',
  textGrey: '#333333',
  backgroundColor: '#F2F2F2',
  mobile: '576px',
  tablet: '767px',
  laptop: '992px',
  desktop: '1200px',
};
