/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
const currentYear = new Date().getFullYear();

export const existingOpusCustomersCopy = {
  section1: {
    title: 'Applications for an opus credit card have closed',
    content: `We're no longer accepting new applications for opus, but we can suggest an alternative  card that could give you a starting credit limit of between £250 to £1,500 and help improve your credit score.`,
  },
  section2: {
    title: 'Existing opus customer?',
    content:
      'You can continue to use opus as the card for all your credit essentials as normal. Just use the opus credit card login below, or head to your opus app.',
  },
  button: {
    text: 'Login',
    link: 'https://portal.opuscard.co.uk/opus/login',
    ariaLabel: 'Login Opens in a new tab',
  },
  section3: {
    content: `Find out more about what's happening in the `,
    link: { text: 'FAQs', href: 'https://portal.opuscard.co.uk/opus/help/faqs/' },
  },
};

export const aquaCardBenefitsCopy = {
  section1: [
    {
      title: 'Credit card for bad credit',
      content:
        'Aqua is designed to help you build your credit score, offering free useful tools and handy guides to support you on your credit journey.',
    },
    {
      title: 'Smart credit limits',
      content:
        'Get a personalised credit limit that adjusts as Aqua gets to know you - and could even grow if you manage your account well.',
    },
    {
      title: 'Use it with your digital wallet',
      content:
        'Make fast and secure payments online, in-app and in-store. Just add your Aqua card to Apple Wallet on your Apple devices or Google Wallet™ on your Android devices.',
    },
  ],

  section2: {
    content: `Find out if you're likely to be approved or declined, before you apply - and without impacting your credit score`,
    button: {
      text: `Explore Aqua`,
      link: `https://www.aquacard.co.uk/`,
      ariaLabel: `Explore Aqua`,
    },
  },
};

export const faqsCopy = {
  title: 'Frequently Asked Questions',

  sections: [
    {
      question: `What's happening to opus?`,
      answer: [
        `We're no longer accepting new applications for opus. However, if you're looking for a card that could help you <a href='https://www.aquacard.co.uk/credit-cards/bad-credit-credit-card' target="_blank" rel="noreferrer" aria-label="Aqua website improve bad credit section Opens in a new tab">improve bad credit</a>, we suggest heading to Aqua.`,
      ],
    },
    {
      question: `I'm a current opus customer. Can I still use my opus card?`,
      answer: [
        `Yes, you'll still be able to carry on using your opus card as normal. You'll also continue to have access to Online Account Manager and the opus app to manage your account.`,
      ],
    },
    {
      question: `What is the relationship between opus and Aqua?`,
      answer: [
        `opus and Aqua are both provided by NewDay Ltd, one of the UK's largest providers of consumer finance. We're committed to helping people move forward with credit - we've even won awards<sup>*</sup> for it.`,
      ],
    },
    {
      question: `Can I check if Aqua is right for me before I apply?`,
      answer: [
        `Yes, just head to <a href='https://www.aquacard.co.uk/' target="_blank" rel="noreferrer" aria-label="Aqua website Opens in a new tab">Aqua</a> and click 'Check my eligibility'. It's quick and easy to do and won't affect your credit rating.`,
      ],
    },
    {
      question: `Can I apply for an Aqua card if I already have an opus card?`,
      answer: [
        `To be eligible for an Aqua card, you must not already have an Aqua card or have taken out an opus, Marbles, Fluid, or Bip card in the last 12 months.`,
      ],
    },
  ],
  note: [
    `<sup>*</sup><strong>Smart Money People's Consumer Credit Awards</strong> winner for Best Credit Building Product Provider, 2022 and 2023`,
  ],
  button: {
    text: `View more FAQs`,
    link: `https://portal.opuscard.co.uk/opus/help/faqs`,
    ariaLabel: `View more FAQs Opens in a new tab`,
  },
};

export const indexMetaInformation = {
  metaTitle: 'opus | The Money Transfer Credit Card',
  metaDescription: `The opus card supports seamless money transfers and helps build up
  your credit rating. Learn more.`,
};

export const aboutMetaInformation = {
  metaTitle: 'About opus | opus Card | The Card For Bad Or Poor Credit Rating',
  metaDescription: `opus credit is provided by NewDay and supports seamless money transfers with the ability to build up a bad credit rating. Learn more.`,
};

export const contactMetaInformation = {
  metaTitle: 'opus | Contact Us',
  metaDescription: `If you have a question about opus, you can contact us via your online account manager, by phone, or by post. Get in touch.`,
};

export const WeRecommendAquaCopy = {
  sections: [
    {
      title: 'The smart alternative',
      content: `Aqua is a great alternative to opus - giving you the power to improve your credit score.`,
    },
    {
      apr: '34.9% APR',
      subTitle: 'Representative (variable) for Aqua Classic',
    },
    {
      button: {
        text: `Discover Aqua`,
        link: `https://www.aquacard.co.uk/`,
        ariaLabel: `Discover Aqua`,
      },
    },
  ],
};

export const dontForgetCopy = {
  title: `Don't forget`,
  content: `Failure to make payments on time or to stay within your credit limit means that you'll pay additional charges, and may make it more expensive and difficult to get credit in the future.`,
};

export const aboutCopy = {
  section1: [
    {
      title: `Finding a credit card for bad credit`,
      content: [
        `
    We understand that life has its ups and downs, and that getting it right every time isn't easy.`,
        `
    We think that many hard-working people with a poor credit history deserve the convenience and freedom of a credit card, and that getting rejected by other credit card companies for bad credit can feel unfair.`,
        `
    While we're no longer accepting new opus applications, you may want to consider Aqua as the smart way to build your credit score - giving you the power to move forward.`,
      ],
      subSections: [
        {
          title: 'Bad credit? Aqua could help',
          content: [
            `
        <a href="https://www.aquacard.co.uk/" target="_blank" rel="noreferrer" aria-label="Aqua website Opens in a new tab">Aqua</a> knows a thing or two about helping those with <a href="https://www.aquacard.co.uk/credit-cards/bad-credit-credit-card" target="_blank" rel="noreferrer" aria-label="Aqua website improve bad credit section Opens in a new tab">bad credit</a> and looks at your circumstances a little more closely than other card providers. If you have a poor credit  history, they'll do their best to say yes, responsibly.`,
            `
    So, if you don't earn as much, your income isn't always regular, or you're moving on from a bump in the road with your credit score, Aqua may be able to offer you the help you need when you need it - whether it's credit building tools or expertise to keep you moving from one day to the next.`,
            `
     Plus, you'll have access to free credit score updates in the Aqua app, and when you manage your finances well, you could build your credit score over time.`,
          ],
        },
      ],
    },
    {
      apr: {
        title: '34.9% APR',
        content: [
          `<span style="font-weight:bold;color:#007680">Representative (variable) for Aqua Classic</span>`,
        ],
        button: {
          text: `Find out more about Aqua`,
          link: `https://www.aquacard.co.uk/`,
          ariaLabel: `Find out more about Aqua`,
        },
      },
    },
  ],

  section2: [
    {
      subSections: [
        {
          title: 'Why are you suggesting Aqua?',
          content: [
            `
  We're no longer accepting new applications for an opus credit card, but we encourage customers to look at joining the thousands of people improving their credit score with Aqua.  `,
          ],
        },

        {
          title: `What's the relationship between opus and Aqua?`,
          content: [
            `
  opus and Aqua are both provided by  <a href="https://www.newday.co.uk/" target="_blank" rel="noreferrer" aria-label="NewDay Ltd Opens in a new tab">NewDay Ltd</a>, one of the UK's largest providers of consumer finance.`,
          ],
        },

        {
          title: 'Who provides our credit at Aqua?',
          content: [
            `
  As with opus, NewDay Ltd is the credit provider. NewDay Ltd and NewDay Cards Ltd are companies registered in England and Wales with registered numbers 7297722 and 4134880 respectively. They form part of the NewDay group of companies.`,
          ],
        },
        {
          title: 'Where are they based?',
          content: [
            `
  The registered office for these companies is 7 Handyside Street, London N1C 4DA.`,
          ],
        },
      ],
    },
  ],
};

export const contactCopy = {
  sections: [
    {
      subTitle:
        'To find out more about the opus credit card or how to manage your existing account, just get in touch.',
      subSections: [
        {
          title: 'By Phone',
          content: [
            `<span>To report your card <strong>lost or stolen</strong> call us 24 hours a day on:</span><br/><br/><strong style="color:#007680">▸0333 414 1077</strong> <br/><br/><span>For <strong>customer enquiries</strong> or to make a payment call us on:</span><br/><br/><strong style="color:#007680">▸0333 414 1077</strong><br/><br/><span>To <strong>activate your opus card</strong> call us 24 hours a day on: </span><br/><br/><strong style="color:#007680"> ▸0808 156 6597*</strong><br/><br/><span> *This is an automated phone service and does not go through to an opus card advisor</span><br/><br/><span>Calls to 0333 numbers will be charged at a standard national rate. Call costs from mobile companies and operators may vary so please check before calling.</span><br/><br/><span>Our lines are open six days a week, 9am to 6pm Mon-Fri, 9am to 4pm Saturday. We are closed on Sunday. Calls may be recorded or monitored for training and security purposes and to help us manage your account.</span>`,
          ],
        },
        {
          title: 'By Post',
          content: [
            '<span>Its normally quicker to call and speak with an opus card advisor on 0333 414 1077, however if you would like to contact us in writing by post, please do so at the following address:</span><br/><br/><strong style="color:#007680">opus Customer Services</strong><br/><span style="color:#007680">PO Box 171<br/>Sheffield<br/>S98 1JS</span>',
          ],
        },
        {
          title: 'Online',
          content: [
            '<span>You can send us a message online by logging into your <a href="https://portal.opuscard.co.uk/opus/login" target="_blank" rel="noreferrer" aria-label=“https://portal.opuscard.co.uk/opus/login Opens in a new tab">online account manager</a></span><br/><br/><span> If you are not registered for our online services, its easy to <a href="https://portal.opuscard.co.uk/opus/register" target="_blank" rel="noreferrer" aria-label=“https://portal.opuscard.co.uk/opus/register Opens in a new tab">sign up</a></span>',
          ],
        },
      ],
    },
  ],
};

export const needToComplaintCopy = {
  title: 'Need to make a complaint?',
  subTitle:
    "We want to help fix any problems that might arise. Get in touch and we'll do everything we can to help",
  button: {
    text: `See opus' complaints procedure`,
    link: `https://portal.opuscard.co.uk/opus/help/faqs/article/i-would-like-to-make-a-complaint-how-can-i-do-this`,
    ariaLabel: `See opus' complaints procedure`,
  },
};

export const footerCopy = {
  bodyOne:
    'Credit available only to UK residents aged 18 and over. Subject to status. Terms apply. The APR applicable to your account will depend on our assessment of your application.',
  links: [
    {
      text: 'Privacy Policy',
      url: 'https://www.newday.co.uk/site-services/privacy-policy/',
      ariaText: 'opens in a new tab',
    },
    {
      text: 'Cookie Policy',
      url: 'https://www.newday.co.uk/site-services/cookies-policy/',
      ariaText: 'opens in a new tab',
    },
    {
      text: 'About Us',
      url: '/about/',
    },
    {
      text: 'Contact Us',
      url: '/contact/',
    },
    {
      text: 'FAQs',
      url: 'https://portal.opuscard.co.uk/opus/help/faqs',
      ariaText: 'opens in a new tab',
    },
  ],

  bodyTwo: `Credit is provided by NewDay Ltd. NewDay Ltd and NewDay Cards Ltd are companies registered in England and Wales with registered numbers 7297722 and 4134880 respectively. They form part of the NewDay group of companies. The registered office for these companies is 7 Handyside Street, London, N1C 4DA. NewDay Ltd and NewDay Cards Ltd are authorised and regulated by the Financial Conduct Authority (FCA) with numbers 690292 and 682417 respectively. NewDay Ltd is also authorised by the FCA under the Payment Services Regulations 2017 (ref no: 555318) for the provision of payment services. opus is a trademark of NewDay Cards Ltd, which is used under licence by NewDay Ltd. Aqua is a trademark of NewDay Cards Ltd, which is used under licence by NewDay Ltd. `,
  bodyThree: `NewDay Ltd is a member of the Finance and Leasing Association (FLA) and it subscribes to the FLA's Lending Code. You can get a copy of this code from www.fla.org.uk.`,
  bodyFour: `© NewDay Ltd. ${currentYear}`,
};
