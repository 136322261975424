import React from 'react';
import { SharedElementsLayout, StyleLayout } from './src/layout';

export const wrapPageElement = ({ element, props }) => {
  return (
    <StyleLayout>
      <SharedElementsLayout path={props.uri}>{element}</SharedElementsLayout>
    </StyleLayout>
  );
};
