import { trackEvents } from './trackApplicationInsights';

//header events//
export const trackHeaderMenuClick = (label) => {
  trackEvents(`header-${label}-click`);
};

export const trackHeaderLoginClick = () => {
  trackEvents(`header-Login-click`);
};

//footer event//
export const trackFooterEvent = (linkText) => {
  trackEvents(`footer-${linkText}-click`);
};

//index page events//
const trackGetActivatedButton = () => {
  trackEvents("home-Let's get activated CTA-click");
};

const trackVideoPlay = () => {
  trackEvents('home-Video started playing');
};

const trackVideoCta = () => {
  trackEvents('home-Video CTA-click');
};

const trackReadMoreButton = () => {
  trackEvents('home-Read more CTA-click');
};

const trackFAQsButton = () => {
  trackEvents('home-View more FAQs CTA-click');
};

const trackFindOutMoreAboutAquaCard = () => {
  trackEvents('home-Find out more about Aqua Card CTA-click');
};

const trackLoginButton = () => {
  trackEvents('home-Login CTA-click');
};

export const indexPageEvents = {
  trackGetActivatedButton,
  trackVideoPlay,
  trackVideoCta,
  trackReadMoreButton,
  trackFAQsButton,
  trackLoginButton,
  trackFindOutMoreAboutAquaCard,
};

//contact page events//
export const trackNavigateToComplaintsProcedure = () => {
  trackEvents('contact-See opus complaints procedure CTA-click');
};

//Faq page events//
export const trackFAQClick = (faqName) => {
  trackEvents(`faqs-${faqName}-click`);
};

// moving to opus Page Events

export const trackMovingToOpusCategoryClick = (category) => {
  trackEvents(`moving-to-opus-${category}-click`);
};

export const trackMovingToOpusQuestionClick = (question) => {
  trackEvents(`moving-to-opus-${question}-click`);
};

export const activateMovingToOpusPageEvents = {
  trackMovingToOpusCategoryClick,
  trackMovingToOpusQuestionClick,
};
