import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import theme from '../utils/theme';
import { GatsbyImage } from 'gatsby-plugin-image';

CookieBanner.propTypes = {
  hideAndSetCookie: PropTypes.func,
  closeIconImage: PropTypes.object,
  cookieIconImage: PropTypes.object,
};

export default function CookieBanner({ closeIconImage, cookieIconImage, hideAndSetCookie }) {
  return (
    <div id="cookieBanner" css={cookieBanner} data-cy="cookie-banner">
      <div className="container">
        <div className="row">
          <div className="col-auto d-flex align-items-center">
            <GatsbyImage image={cookieIconImage} alt="Cookie Icon" />
          </div>
          <div className="col d-flex align-items-center">
            <span>This website only uses strictly necessary cookies.</span>
          </div>
          <button
            id="cookieBannerCloseIcon"
            className="col-auto d-flex align-items-center justify-content-end"
            onClick={hideAndSetCookie}
            data-cy="close-icon"
            css={closeIconCSS}
          >
            <GatsbyImage image={closeIconImage} alt="Close Icon" />
          </button>
        </div>
      </div>
    </div>
  );
}

const cookieBanner = css`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${theme.teal};
  color: ${theme.white};
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 1.5rem 0;
  z-index: 1;
`;

const closeIconCSS = css`
  border: none;
  background: transparent;
  cursor: pointer;
  :focus {
    outline: solid ${theme.white};
  }

  transition: opacity 0.6s ease-in-out;
  &:hover {
    opacity: 0.6;
  }
`;
