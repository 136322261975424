export const COOKIES_CONSENT = 'cookiesConsent';

export const setCookie = (name, value) => {
  if (typeof document !== 'undefined') {
    document.cookie = `${name}=${value}; path=${window.location.origin}`;
  }
};

export const isCookieSet = name =>
  typeof document === 'undefined'
    ? true
    : document.cookie.indexOf(`${name}=1`) > -1;
