import React from 'react';

const ApplePayMark = () => (
  <svg
    width="70"
    height="35"
    viewBox="0 0 95 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="apple-pay-mark"
  >
    <title id="apple-pay-mark">Apple Pay</title>
    <g clipPath="url(#clip0_102_152)">
      <path
        d="M86.4924 0H8.50769C8.18289 0 7.85752 0 7.53331 0.00189402C7.25924 0.00384543 6.98582 0.00688734 6.71239 0.0143486C6.11606 0.0304765 5.51462 0.0656593 4.92575 0.171495C4.32747 0.279167 3.77075 0.454794 3.22762 0.731321C2.69368 1.00285 2.20485 1.35801 1.78123 1.78181C1.35742 2.20561 1.00227 2.69364 0.73079 3.22815C0.454206 3.77128 0.278464 4.32818 0.171538 4.92692C0.0651347 5.51596 0.0297166 6.11728 0.0136461 6.71298C0.00629962 6.9864 0.00314866 7.25983 0.00136369 7.5332C-0.000530325 7.85811 0.000101014 8.18279 0.000101014 8.50828V52.3105C0.000101014 52.636 -0.000530325 52.9601 0.00136369 53.2856C0.00314866 53.559 0.00629962 53.8324 0.0136461 54.1059C0.0297166 54.701 0.0651347 55.3024 0.171538 55.8912C0.278464 56.4902 0.454206 57.0469 0.73079 57.59C1.00227 58.1246 1.35742 58.6132 1.78123 59.0364C2.20485 59.4609 2.69368 59.816 3.22762 60.0868C3.77075 60.3641 4.32747 60.5398 4.92575 60.6474C5.51462 60.7525 6.11606 60.7884 6.71239 60.8045C6.98582 60.8107 7.25924 60.8144 7.53331 60.8156C7.85752 60.8181 8.18289 60.8181 8.50769 60.8181H86.4924C86.8166 60.8181 87.142 60.8181 87.4662 60.8156C87.7396 60.8144 88.013 60.8107 88.2877 60.8045C88.8828 60.7884 89.4841 60.7525 90.0744 60.6474C90.6719 60.5398 91.2287 60.3641 91.7719 60.0868C92.3064 59.816 92.7938 59.4609 93.2183 59.0364C93.6414 58.6132 93.9966 58.1246 94.2687 57.59C94.5459 57.0469 94.7215 56.4902 94.8279 55.8912C94.9344 55.3024 94.9689 54.701 94.9851 54.1059C94.9925 53.8324 94.9962 53.559 94.9975 53.2856C95 52.9601 95 52.636 95 52.3105V8.50828C95 8.18279 95 7.85811 94.9975 7.5332C94.9962 7.25983 94.9925 6.9864 94.9851 6.71298C94.9689 6.11728 94.9344 5.51596 94.8279 4.92692C94.7215 4.32818 94.5459 3.77128 94.2687 3.22815C93.9966 2.69364 93.6414 2.20561 93.2183 1.78181C92.7938 1.35801 92.3064 1.00285 91.7719 0.731321C91.2287 0.454794 90.6719 0.279167 90.0744 0.171495C89.4841 0.0656593 88.8828 0.0304765 88.2877 0.0143486C88.013 0.00688734 87.7396 0.00384543 87.4662 0.00189402C87.142 0 86.8166 0 86.4924 0Z"
        fill="black"
      />
      <path
        d="M86.4924 2.02722L87.4518 2.02906C87.7116 2.03089 87.9715 2.03371 88.2329 2.04082C88.6874 2.05311 89.2192 2.07773 89.7148 2.16658C90.1457 2.24417 90.507 2.36218 90.8538 2.53872C91.1962 2.71268 91.5099 2.94066 91.7837 3.21414C92.0586 3.48946 92.2869 3.80364 92.4632 4.14979C92.6387 4.49387 92.756 4.8535 92.8331 5.28752C92.9217 5.77778 92.9463 6.31097 92.9587 6.76835C92.9657 7.02651 92.9691 7.28467 92.9704 7.54898C92.9729 7.86861 92.9728 8.18806 92.9728 8.50832V52.3105C92.9728 52.6308 92.9729 52.9497 92.9704 53.2762C92.9692 53.5343 92.9657 53.7924 92.9587 54.051C92.9463 54.5077 92.9218 55.0406 92.832 55.5367C92.756 55.9647 92.6388 56.3244 92.4623 56.6702C92.2865 57.0155 92.0586 57.3294 91.7849 57.603C91.5094 57.8784 91.1968 58.1057 90.8504 58.2813C90.5062 58.457 90.1455 58.5749 89.7188 58.6517C89.2131 58.7418 88.6591 58.7666 88.2419 58.7779C87.9793 58.7838 87.7181 58.7873 87.4505 58.7886C87.1315 58.7911 86.8114 58.791 86.4924 58.791H8.50769C8.50345 58.791 8.49931 58.791 8.49501 58.791C8.17968 58.791 7.86373 58.791 7.54266 58.7886C7.28089 58.7873 7.01968 58.7838 6.76715 58.7781C6.34036 58.7666 5.78599 58.7418 5.28442 58.6522C4.85414 58.5749 4.49341 58.457 4.14468 58.279C3.80152 58.105 3.48912 57.8779 3.21357 57.6019C2.94014 57.329 2.71291 57.0161 2.53723 56.6703C2.36137 56.3248 2.24371 55.9641 2.16635 55.5309C2.07693 55.0358 2.05236 54.505 2.04008 54.0514C2.03308 53.7917 2.03015 53.5321 2.02848 53.2739L2.02722 52.5118L2.02728 52.3105V8.50832L2.02722 8.3071L2.02843 7.54656C2.03015 7.28685 2.03308 7.0272 2.04008 6.76772C2.05236 6.31367 2.07693 5.78266 2.16709 5.28344C2.24377 4.85419 2.36137 4.49341 2.53815 4.14623C2.71245 3.80307 2.94008 3.48981 3.21495 3.215C3.48872 2.94111 3.80215 2.71343 4.14749 2.5378C4.49249 2.36212 4.8539 2.24417 5.28419 2.16675C5.77996 2.07767 6.31207 2.05311 6.76778 2.04077C7.02755 2.03371 7.28731 2.03089 7.54513 2.02912L8.50769 2.02722H86.4924"
        fill="white"
      />
      <path
        d="M25.9344 20.4557C26.7478 19.4383 27.2998 18.0722 27.1542 16.6763C25.9635 16.7355 24.5104 17.4618 23.6692 18.48C22.9138 19.352 22.2453 20.7753 22.4196 22.1127C23.7562 22.2287 25.0916 21.4446 25.9344 20.4557Z"
        fill="black"
      />
      <path
        d="M27.139 22.3738C25.1979 22.2582 23.5474 23.4755 22.6204 23.4755C21.6929 23.4755 20.2734 22.4321 18.7381 22.4602C16.7397 22.4896 14.8855 23.6195 13.8714 25.4165C11.7856 29.0115 13.321 34.344 15.3493 37.2719C16.3343 38.7205 17.5214 40.3154 19.0855 40.2581C20.5634 40.2001 21.1427 39.3011 22.9391 39.3011C24.7342 39.3011 25.256 40.2581 26.8204 40.2291C28.4427 40.2001 29.457 38.7799 30.442 37.3299C31.572 35.6787 32.0345 34.0843 32.0636 33.9968C32.0345 33.9678 28.9353 32.7788 28.9065 29.2137C28.8773 26.2286 31.3398 24.8088 31.4557 24.7207C30.0651 22.664 27.8923 22.4321 27.139 22.3738Z"
        fill="black"
      />
      <path
        d="M44.041 18.334C48.2601 18.334 51.1979 21.2422 51.1979 25.4764C51.1979 29.7257 48.1996 32.649 43.9352 32.649H39.2639V40.0777H35.8889V18.334H44.041V18.334ZM39.2639 29.816H43.1365C46.075 29.816 47.7474 28.234 47.7474 25.4915C47.7474 22.7492 46.075 21.182 43.1516 21.182H39.2639V29.816V29.816Z"
        fill="black"
      />
      <path
        d="M52.0798 35.5724C52.0798 32.7996 54.2044 31.097 57.9718 30.8859L62.3112 30.6299V29.4095C62.3112 27.6464 61.1207 26.5916 59.1321 26.5916C57.2481 26.5916 56.0727 27.4955 55.7867 28.9122H52.7128C52.8936 26.049 55.3344 23.9395 59.2524 23.9395C63.0948 23.9395 65.5507 25.9737 65.5507 29.1531V40.0777H62.4315V37.4709H62.3565C61.4375 39.2339 59.4332 40.3489 57.3539 40.3489C54.2497 40.3489 52.0798 38.4201 52.0798 35.5724ZM62.3112 34.1409V32.8903L58.4083 33.1312C56.4645 33.2669 55.3647 34.1258 55.3647 35.482C55.3647 36.8681 56.5098 37.7723 58.2578 37.7723C60.533 37.7723 62.3112 36.2051 62.3112 34.1409Z"
        fill="black"
      />
      <path
        d="M68.4956 45.9092V43.2722C68.7362 43.3323 69.2786 43.3323 69.5501 43.3323C71.0568 43.3323 71.8706 42.6996 72.3676 41.0723C72.3676 41.042 72.6542 40.1079 72.6542 40.0928L66.9284 24.2257H70.454L74.4626 37.1244H74.5225L78.5311 24.2257H81.9665L76.0292 40.9063C74.6736 44.749 73.1064 45.9845 69.8215 45.9845C69.5501 45.9845 68.7362 45.9543 68.4956 45.9092Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_102_152">
        <rect width="95" height="61" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ApplePayMark;
